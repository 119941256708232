import { Link } from "@nextui-org/link";
import {
  Navbar as NextUINavbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
} from "@nextui-org/navbar";
import { link as linkStyles } from "@nextui-org/theme";
import clsx from "clsx";
import { Tooltip } from "@nextui-org/tooltip";
import { ScrollShadow } from "@nextui-org/scroll-shadow";

import React, { useEffect, useState } from "react";
import { Input } from "@nextui-org/input";

// import Settings from "./settings";
import { CrownIcon, LogOutIcon, UserIcon } from "lucide-react";
import { Button } from "@nextui-org/button";

import StreamList from "./streamList";
import CreateSource from "./createSource";

import { siteConfig } from "@/config/site";
import { ThemeSwitch } from "@/components/theme-switch";
import { Logo } from "@/components/icons";
import useAppStore from "@/store/store";
import { Channel } from "@/proivder/channelProvider";
import { AuthProvider } from "@/proivder/authProvider";

import Login from "./login";
import { TokenProvider } from "@/proivder/tokenProvider";
import Plans from "./plans";

export const Navbar = () => {
  const channels = useAppStore((v) => v.channels);
  const channel = useAppStore((v) => v.channel);
  const player = useAppStore((v) => v.videoPlayer);

  const reloadVideo = useAppStore((v) => v.reloadVideo);

  const setChannel = useAppStore((v) => v.setChannel);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const userRoles = useAppStore((v) => v.userRoles);

  const [keyword, setKeyword] = React.useState("");

  const [, setIsOpen] = useState(false);

  const [updateSource, setUpdateSource] = useState<Channel | null>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isLoginRequired = useAppStore((v) => v.loginRequired);
  const setIsLoginRequired = useAppStore((v) => v.setLoginRequired);

  const [isPremiumOpenend, setIsPremiumOpenend] = useState(false);
  const [isLoginOpenend, setIsLoginOpenend] = useState(false);

  const setLoggedIn = useAppStore((v) => v.setLoggedIn);

  const getRoles = useAppStore((v) => v.getRoles);

  function handleUpdateSource(channel: Channel | null) {
    setUpdateSource(channel);
  }

  function handleCheckAuthentication() {
    // setIsLoggedIn(!!authProvider.userIsLoggedIn);

    // check url has session_state query param

    if (window.location.search.includes("session_state")) {
      const authProvider = AuthProvider.getInstance();

      authProvider.onAuthentificationChange((isLoggedIn) => {
        setIsLoggedIn(isLoggedIn);
        // remove session_state query param
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        getRoles();
        setLoggedIn(isLoggedIn);
        reloadVideo();
      });
    }

    const token = TokenProvider.getToken();

    if (token) {
      setIsLoggedIn(true);
      setLoggedIn(true);

      getRoles();
      reloadVideo();
    } else {
      setIsLoggedIn(false);
      setLoggedIn(false);
    }

    // scroll to top
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    handleCheckAuthentication();
  }, []);

  useEffect(() => {
    if (isLoginRequired) {
      setIsMenuOpen(true);
      setIsLoginOpenend(true);
      setIsLoginRequired(false);
    }
  }, [isLoginRequired]);
  const isMobile = window.innerWidth <= 640;

  return (
    <NextUINavbar
      isMenuOpen={isMenuOpen}
      maxWidth="xl"
      position="sticky"
      onMenuOpenChange={(v) => {
        setIsMenuOpen(v);
        if (!v) {
          setIsPremiumOpenend(false);
          setIsLoginOpenend(false);
          window.scrollTo(0, 0);
        }
      }}
    >
      <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
        <NavbarBrand className="gap-3 max-w-fit">
          <Link
            className="flex justify-start items-center gap-2"
            color="foreground"
            href="/"
          >
            <Logo />
            <p className="font-bold text-lg">EMS BT</p>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex basis-1/5 sm:basis-full"
        justify="end"
      >
        <NavbarItem className="hidden sm:flex gap-4 items-center">
          {!isMobile && (
            <CreateSource
              editItem={updateSource}
              onCreated={() => setIsOpen(false)}
              onUpdate={() => handleUpdateSource(null)}
            />
          )}

          {!userRoles.includes("admin") && !userRoles.includes("paid_user") ? (
            <NavbarMenuToggle
              icon={
                <Tooltip content="Become Premium">
                  <Button
                    isIconOnly
                    size="sm"
                    onClick={() => {
                      setIsMenuOpen(true);
                      setIsPremiumOpenend(true);
                    }}
                  >
                    <CrownIcon size={18} />
                  </Button>
                </Tooltip>
              }
            />
          ) : null}

          {isLoggedIn ? (
            <Tooltip content="Logout">
              <Button
                onClick={() => {
                  const authProvider = AuthProvider.getInstance();
                  authProvider.logout();
                  setLoggedIn(false);
                }}
                isIconOnly
                size="sm"
              >
                <LogOutIcon size={18} />
              </Button>
            </Tooltip>
          ) : (
            <NavbarMenuToggle
              icon={
                <Tooltip content="Login">
                  <Button
                    onClick={() => {
                      setIsMenuOpen(true);
                      setIsLoginOpenend(true);
                    }}
                    isIconOnly
                    size="sm"
                  >
                    <UserIcon size={18} />
                  </Button>
                </Tooltip>
              }
            />
          )}

          {/* <div className="hidden lg:flex  justify-end mr-1">
            {siteConfig.navItems.map((item) => (
              <Tooltip key={item.href} content="About Us">
                <Link
                  key={item.href}
                  className={clsx(
                    linkStyles({ color: "foreground" }),
                    "data-[active=true]:text-primary data-[active=true]:font-medium"
                  )}
                  color="foreground"
                  href={item.href}
                >
                  {<item.label size={20} />}
                </Link>
              </Tooltip>
            ))}
          </div> */}

          {/* <Settings /> */}
        </NavbarItem>
        <div className=" hidden">
          <ThemeSwitch />
        </div>
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
        {!userRoles.includes("admin") && !userRoles.includes("paid_user") ? (
          <Tooltip content="Become Premium">
            <Button
              isIconOnly
              size="sm"
              onClick={() => {
                setIsMenuOpen(true);
                setIsPremiumOpenend(true);
              }}
            >
              <CrownIcon size={18} />
            </Button>
          </Tooltip>
        ) : null}

        {isLoggedIn ? (
          <Tooltip content="Logout">
            <Button
              onClick={() => {
                const authProvider = AuthProvider.getInstance();

                authProvider.logout();
              }}
              isIconOnly
              size="sm"
            >
              <LogOutIcon size={18} />
            </Button>
          </Tooltip>
        ) : (
          <NavbarMenuToggle
            icon={
              <Tooltip content="Login">
                <Button
                  onClick={() => {
                    setIsMenuOpen(true);
                    setIsLoginOpenend(true);
                  }}
                  isIconOnly
                  size="sm"
                >
                  <UserIcon size={18} />
                </Button>
              </Tooltip>
            }
          />
        )}

        {/* <Settings /> */}
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarMenu>
        {isLoginOpenend ? (
          <Login
            onClose={() => {
              setIsMenuOpen(false);
              setIsPremiumOpenend(false);
              handleCheckAuthentication();
              setIsLoginOpenend(false);
            }}
          />
        ) : isPremiumOpenend ? (
          <Plans />
        ) : (
          <div className="mt-2 flex flex-col gap-2 justify-center">
            <div className=" gap-2 mb-2 flex-row-reverse container mx-auto max-w-6xl flex">
              <ThemeSwitch />

              <div className="flex  justify-end mr-1">
                {siteConfig.navItems.map((item) => (
                  <Tooltip key={item.href} content="About Us">
                    <Link
                      key={item.href}
                      className={clsx(
                        linkStyles({ color: "foreground" }),
                        "data-[active=true]:text-primary data-[active=true]:font-medium"
                      )}
                      color="foreground"
                      href={item.href}
                    >
                      {<item.label size={20} />}
                    </Link>
                  </Tooltip>
                ))}
              </div>
            </div>
            <Input
              className="w-full"
              placeholder="Search"
              value={keyword}
              onChange={(v) => setKeyword(v.target.value)}
            />
            <ScrollShadow className="flex-grow overflow-scroll scrollbar-hide w-full justify-center">
              <StreamList
                channel={channel!}
                items={channels.filter((c) =>
                  c.name.toLowerCase().includes(keyword.toLowerCase())
                )}
                videoPlayer={player!}
                onChannel={(c) => setChannel(c)}
                onDelete={() => null}
                onExport={() => null}
                onPlay={() => setIsMenuOpen(false)}
                onUpdate={() => null}
              />
            </ScrollShadow>
          </div>
        )}
      </NavbarMenu>
    </NextUINavbar>
  );
};
