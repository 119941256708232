const LANGUAGES_KEY = "languages";
const SELECTED_LANGUAGES_KEY = "user_selected_languages";
const IS_MULTIPLE_SUBTITLES = "isMultipleSubtiles";

export class LanguageProvider extends EventTarget {
  constructor() {
    super();
  }
  getLanguages(langs?: string[]) {
    const languages = localStorage.getItem(LANGUAGES_KEY);

    if (languages) {
      return JSON.parse(languages);
    } else {
      const defaultLanguages =
        langs && langs.length
          ? langs
          : [
              "en",
              "tr",
              "ar",
              "fr",
              "es",
              "de",
              "it",
              "pt",
              "ru",
              "zh",
              "ja",
              "ko",
              "ir",
              "el",
            ];

      localStorage.setItem(LANGUAGES_KEY, JSON.stringify(defaultLanguages));

      return defaultLanguages;
    }
  }

  setLanguages(language: string) {
    var languages = this.getLanguages();

    if (!languages.includes(language)) {
      languages.push(language);
    }

    localStorage.setItem(LANGUAGES_KEY, JSON.stringify(languages));
  }

  setSelectedLanguages(languages: string[]) {
    const selectedLanguages = this.getSelectedLanguages();

    //  check if the selected languages are the same as the default languages

    if (JSON.stringify(languages) === JSON.stringify(selectedLanguages)) {
      localStorage.setItem(SELECTED_LANGUAGES_KEY, JSON.stringify(languages));
      this.dispatchEvent(
        new CustomEvent("selectedLanguagesChanged", { detail: languages })
      );
    }
  }

  getSelectedLanguages() {
    const selectedLanguages = localStorage.getItem(SELECTED_LANGUAGES_KEY);

    if (selectedLanguages) {
      return JSON.parse(selectedLanguages);
    } else {
      const defaultLanguages = this.getLanguages();

      localStorage.setItem(
        SELECTED_LANGUAGES_KEY,
        JSON.stringify(defaultLanguages)
      );

      return defaultLanguages;
    }
  }

  isMultipleSubtiles(): boolean {
    const isMumltiple = localStorage.getItem(IS_MULTIPLE_SUBTITLES);

    if (isMumltiple) {
      return JSON.parse(isMumltiple);
    } else {
      // localStorage.setItem("isMultipleSubtiles", JSON.stringify(true));

      return true;
    }
  }

  setMultipleSubtiles(isMultiple: boolean) {
    localStorage.setItem(IS_MULTIPLE_SUBTITLES, JSON.stringify(isMultiple));

    this.dispatchEvent(
      new CustomEvent("multipleSubtitlesChanged", { detail: isMultiple })
    );
  }

  getChannelLanguages(channelId: number, _: string[], audioLang: string) {
    const selectedLanguages = localStorage.getItem(
      SELECTED_LANGUAGES_KEY + channelId
    );

    if (selectedLanguages) {
      return JSON.parse(selectedLanguages);
    }

    // language from agent browser
    const defaultLangs = window.navigator.languages.filter(
      (lang) => lang.length === 2
    );

    return audioLang !== "en"
      ? [audioLang, defaultLangs[0]]
      : [
          "en",
          defaultLangs.length && defaultLangs[0] !== "en"
            ? defaultLangs[0]
            : defaultLangs.length && defaultLangs[1]
              ? defaultLangs[1]
              : "",
        ];
  }

  setChannelLanguage(
    channelId: number,
    language: string,
    channelLanguages: string[]
  ) {
    // channelLanguages[1] = language;

    localStorage.setItem(
      SELECTED_LANGUAGES_KEY + channelId,
      JSON.stringify([channelLanguages[0], language])
    );

    this.dispatchEvent(
      new CustomEvent("multipleSubtitlesChanged", { detail: channelLanguages })
    );
  }
}
