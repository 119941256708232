import Hls from "hls.js";
import { UserProvider } from "./userProvider";
import { uniqueId } from "./authProvider";

// Function to refresh the token (mockup example)
function refreshToken() {
  return new Promise((resolve, _) => {
    // Example of an API call to refresh the token
    UserProvider.refreshToken().then((response) => {
      const newToken = response.data.access_token;
      resolve(newToken);
    });
  });
}

// Function to update the URL with the current token
function appendTokenToUrl(url: string) {
  if(url.includes("token")) return url;
  const token = localStorage.getItem("token") ?? "";
  const urlObj = new URL(url);

  urlObj.searchParams.set("token", token); // Append or update the 'token' query parameter
  urlObj.searchParams.set("device_id", uniqueId()); // Append or update the 'device_id' query parameter

  return urlObj.toString();
}

export function hlsRequestInterceptor(hls: Hls | null) {
  const interceptor = (xhr: XMLHttpRequest, url: string) => {
    // Intercept and modify the request URL before fetching .m3u8 or .ts files

    xhr.open("GET", appendTokenToUrl(url), true); // Modify the URL to include the token

    // Optionally, you can set additional headers here (e.g., Authorization headers)
    // xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 403) {
        // Token might be expired, refresh the token
        refreshToken()
          .then((_) => {
            // // Retry the request with the new token
            hls?.stopLoad(); // Stop current load

            hls?.startLoad(); // Start loading again
          })
          .catch((err) => {
            console.error("Failed to refresh token: ", err);
          });
      }
    };
  };

  return interceptor;
}
