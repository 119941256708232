import Hls from "hls.js";

class CustomLoader extends Hls.DefaultConfig.loader {
  constructor(config: any) {
    super(config);
  }

  load(context: any, config: any, callbacks: any): void {
    // Custom logic before loading

    // Call the original load method
    super.load(context, config, {
      onSuccess: (response, stats, context) => {
        callbacks.onSuccess(response, stats, context);
      },
      onError: (response, context) => {
        callbacks.onError(response, context);
      },
      onTimeout: (stats, context) => {
        callbacks.onTimeout(stats, context);
      },
    });
  }
  abort(): void {}
}

export default CustomLoader;
