export class TokenProvider {
  static getToken(): string | null {
    return localStorage.getItem("token");
  }

  static setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  static setRefreshToken(token: string): void {
    localStorage.setItem("refreshToken", token);
  }

  static setIdToken(token: string): void {
    localStorage.setItem("id_token", token);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }



  static removeToken(): void {
    localStorage.removeItem("token");
  }
}
