import { useState, useEffect } from "react";


import { ChevronDown, Search, Clock } from "lucide-react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import useAppStore from "@/store/store";
import { Modal, ModalContent, ModalHeader } from "@nextui-org/modal";
import { ChannelProvider, TrnascriptModel } from "@/proivder/channelProvider";

interface LanguageSelectorProps {
  onSelect: (lang: string) => void;
  
}

export default function LanguageSelector({ onSelect }: LanguageSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [searchQuery, setSearchQuery] = useState("");
  const [recentLanguages, setRecentLanguages] = useState<string[]>([]);
  const allLanguages = useAppStore((state) => state.languages);

  const [transcriptModels, setTranscriptModels] = useState<TrnascriptModel[]>(
    []
  );

  async function loadTranscriptModels() {
    const models = await ChannelProvider.getTranscriptModels();
    console.log(models);
    setTranscriptModels(models);
  }


  useEffect(() => {
    // Load recent languages from localStorage on component mount
    const storedRecent = localStorage.getItem("recentLanguages");
    if (storedRecent) {
      setRecentLanguages(JSON.parse(storedRecent));
    }

    loadTranscriptModels();
  }, []);

  const updateRecentLanguages = (lang: string) => {
    const updatedRecent = [
      lang,
      ...recentLanguages.filter((l) => l !== lang),
    ].slice(0, 3);
    setRecentLanguages(updatedRecent);
    localStorage.setItem("recentLanguages", JSON.stringify(updatedRecent));
  };

  const selectLanguage = (lang: string) => {
    setSelectedLanguage(lang);
    updateRecentLanguages(lang);
    setIsOpen(false);
    onSelect(lang);
  };

  const filteredLanguages = allLanguages.filter((lang) =>
    lang.language.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        variant="bordered"
        className="w-full justify-between"
      >
        {allLanguages.find((lang) => lang.code === selectedLanguage)?.language}
        <ChevronDown className="ml-2 h-4 w-4" />
      </Button>
      <Modal isOpen={isOpen} onOpenChange={setIsOpen} size="3xl">
        <ModalContent className=" p-4">
          <ModalHeader>
            <h2 className=" text-xl font-semibold">Select Source language</h2>
          </ModalHeader>
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search languages"
              className="pl-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {recentLanguages.length > 0 && (
            <div className="mt-4">
              <h3 className="mb-2 text-sm font-medium text-muted-foreground flex items-center">
                <Clock className="mr-2 h-4 w-4" />
                Recent
              </h3>
              <div className="grid grid-cols-3 gap-2">
                {allLanguages
                  .filter((item) => recentLanguages.includes(item.code))
                  .map((lang) => (
                    <Button
                      key={lang.code}
                      variant="flat"
                      color="secondary"
                      className="justify-start font-normal"
                      onClick={() => selectLanguage(lang.code)}
                    >
                      {lang.language}
                    </Button>
                  ))}
              </div>
            </div>
          )}
          <ScrollShadow className="h-[300px] mt-4">
            <div className="grid grid-cols-2 gap-2">
              {filteredLanguages.map((lang) => (
                <Button
                  key={lang.code}
                  variant="ghost"
                  className="justify-start font-normal"
                  onClick={() => selectLanguage(lang.code)}
                  disabled={!!(!transcriptModels.find((model) => model.model_name === lang.code && model.load))}
                  isDisabled={!!(!transcriptModels.find((model) => model.model_name === lang.code && model.load))}
                >
                  {lang.language}
                </Button>
              ))}
            </div>
          </ScrollShadow>
        </ModalContent>
      </Modal>
    </>
  );
}
