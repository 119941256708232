import { title } from "@/components/primitives";
import DefaultLayout from "@/layouts/default";

export default function DocsPage() {
  return (
    <DefaultLayout>
      <section className="flex flex-col items-center justify-between h-full gap-4 py-8 md:py-10">
        <div className="inline-block max-w-lg text-center justify-center">
          <h1 className={title()}>EMS BT Application</h1>
          <p className="text-lg text-center text-muted-foreground m-6">
            &copy; EMS Broadcast Technology LLC
          </p>
          <p className="text-lg text-center text-muted-foreground m-6">
            Contact us at{" "}
            <a href="mailto:mailto:info@emsbt.ai" className=" underline">
              {" "}
              info@emsbt.ai{" "}
            </a>
          </p>
        </div>
        <a href="/privacy-and-policy"> Emsbt Privacy and Policy </a>
      </section>
    </DefaultLayout>
  );
}
