

import { Button } from "@nextui-org/button";
import { Progress } from "@nextui-org/progress";
import { CheckCircle2, XCircle } from "lucide-react"

export default function Plans() {
  return (
    <div className="container mx-auto p-4 pb-28 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">AI Transcript & Translate</h1>
        <Button variant="bordered">Upgrade to Enterprise</Button>
      </div>

      <div className="space-y-6">
        <section className="space-y-4 bg-gray-50 bg-opacity-10 p-4 rounded-sm">
          <h2 className="text-xl font-semibold">Pro plan</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureItem text="Advanced transcription" available={true} />
            <FeatureItem text="20 languages for translation" available={true} />
            <FeatureItem text="10 hours of audio per month" available={true} />
            <FeatureItem text="High accuracy" available={true} />
            <FeatureItem text="Advanced formatting options" available={true} />
            <FeatureItem text="Priority email support" available={true} />
            <FeatureItem text="Basic AI features" available={true} />
            <FeatureItem text="Custom vocabulary" available={true} />
            <FeatureItem text="Unlimited audio" available={false} />
          </div>
          <Button variant="ghost" className="text-sm">See all features</Button>
        </section>

        <section className="space-y-2">
          <h3 className="font-medium">2/10 hours used</h3>
          <Progress value={20} className="w-full" />
          <p className="text-sm text-muted-foreground">You have 8 hours left this month</p>
        </section>

        <section className="space-y-2">
          <div className="flex justify-between items-center">
            <h3 className="font-medium">5 Users</h3>
            <Button variant="ghost" className="text-sm">Manage users</Button>
          </div>
        </section>

        <section className="space-y-4 bg-gray-50 bg-opacity-10 p-4">
          <h2 className="text-xl font-semibold">AI Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureItem text="Advanced language detection" available={true} />
            <FeatureItem text="Advanced grammar correction" available={true} />
            <FeatureItem text="Basic sentiment analysis" available={true} />
            <FeatureItem text="Speaker diarization" available={true} />
            <FeatureItem text="Custom AI model training" available={false} />
          </div>
          <Button variant="ghost" className="text-sm">See all features</Button>
        </section>

        <section className="space-y-4 bg-gray-50 bg-opacity-10 round-sm p-4">
          <h2 className="text-xl font-semibold">Add-ons</h2>
          <div className="space-y-4">
            <AddOnItem 
              title="Extra languages" 
              description="5 additional languages"
              actionText="Add more languages"
            />
            <AddOnItem 
              title="Extended audio time" 
              description="20 additional hours"
              actionText="Add more hours"
            />
            <AddOnItem 
              title="Custom AI model" 
              description="Train AI on your specific content"
              actionText="Upgrade to Enterprise"
            />
          </div>
        </section>

        <section className="mt-8 p-6 bg-gray-50 bg-opacity-10 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Enterprise plan</h2>
          <p className="mb-4">For businesses needing advanced features, unlimited usage, and dedicated support.</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Unlimited audio processing</li>
            <li>All languages included</li>
            <li>Custom AI model training</li>
            <li>Advanced analytics and reporting</li>
            <li>Dedicated account manager</li>
            <li>24/7 priority support</li>
          </ul>
          <Button>Contact sales</Button>
        </section>
      </div>
    </div>
  )
}

function FeatureItem({ text, available }: { text: string; available: boolean }) {
  return (
    <div className="flex items-center space-x-2">
      {available ? (
        <CheckCircle2 className="w-5 h-5 text-green-500" />
      ) : (
        <XCircle className="w-5 h-5 text-gray-300" />
      )}
      <span className={available ? "" : "text-muted-foreground"}>{text}</span>
    </div>
  )
}

function AddOnItem({ title, description, actionText }: { title: string; description: string; actionText: string }) {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h3 className="font-medium">{title}</h3>
        <p className="text-sm text-muted-foreground">{description}</p>
      </div>
      <Button variant="solid" color="secondary" size="md">{actionText}</Button>
    </div>
  )
}