import { Route, Routes } from "react-router-dom";

import IndexPage from "@/pages/index";
import DocsPage from "@/pages/docs";
import PricingPage from "@/pages/pricing";
import AboutPage from "@/pages/about";

// // add global.css
 import "../app/globals.css";
import BecomePremium from "./pages/becomePremium";
import PrivacyAndPolicy from "./pages/privacyAndPolicy";

function App() {
  return (
    <Routes>
      <Route element={<IndexPage />} path="/" />
      <Route element={<DocsPage />} path="/docs" />
      <Route element={<PrivacyAndPolicy />} path="/privacy-and-policy" />
      <Route element={<PricingPage />} path="/pricing" />
      <Route element={<AboutPage />} path="/about" />
      <Route element={<BecomePremium />} path="/premium" />
    </Routes>
  );
}

export default App;
