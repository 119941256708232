// Utility functions

import { Subtitle } from "@/components/videoPlayer";
import { BASE_HLS_URL } from "@/constants/urls";

// /**
//  * Convert MPEGTS timestamp to seconds (MPEGTS uses 90kHz clock).
//  * @param {number} mpegts - MPEGTS timestamp.
//  * @returns {number} - Time in seconds.
//  */
// function mpegtsToSeconds(mpegts: number): number {
//   const MPEGTS_CLOCK = 90000; // 90 kHz clock
//   return mpegts / MPEGTS_CLOCK;
// }

/**
 * Convert LOCAL WebVTT time format (HH:MM:SS.mmm) to seconds.
 * @param {string} localTime - LOCAL time in WebVTT format.
 * @returns {number} - Time in seconds.
 */
function localTimeToSeconds(localTime: string): number {
  const timeParts = localTime.split(/[:.]/).map(Number);
  return (
    timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2] + timeParts[3] / 1000
  );
}

/**
 * Parse X-TIMESTAMP-MAP to extract MPEGTS and LOCAL mapping.
 * @param {string} xTimestampMap - X-TIMESTAMP-MAP line.
 * @returns {{mpegts: number, local: number}} - Mapping between MPEGTS and LOCAL in seconds.
 */
function parseTimestampMap(xTimestampMap: string): {
  mpegts: number;
  local: number;
} {
  const regex = /MPEGTS:(\d+),LOCAL:(\d{2}:\d{2}:\d{2}\.\d{3})/;
  const match = regex.exec(xTimestampMap);

  if (!match) {
    throw new Error("Invalid X-TIMESTAMP-MAP format");
  }

  const mpegts = parseInt(match[1], 10);
  const local = localTimeToSeconds(match[2]);

  return { mpegts, local };
}



// function extractTimestamp(url: string): string | null {
//   // Regular expression to match the timestamp in the URL
//   const regex = /\/(\d+\.\d+)\.vtt/;
//   const match = url.match(regex);

//   // If a match is found, return the first capturing group
//   if (match && match[1]) {
//     return match[1];
//   }

//   // If no match is found, return null
//   return null;
// }

// Main function to calculate the in and out times

/**
 * Calculate in and out times for WebVTT cues based on the playlist time and MPEGTS mapping.
 * @param {string} vttContent - The WebVTT content.
 * @param {number} playlistUnixTime - Unix time from the playlist URL.
 * @returns {{inTime: number, outTime: number, duration: number}}[] - Array of in/out times and durations for each cue.
 */
export function calculateInOutTimes(
  vttContent: string,
  _vttUrl: string,
  initPts: number
) {
  // const playlistUnixTime = parseFloat(
  //   new URL(playlisUrl).searchParams.get("time") ?? "0"
  // );

  // const vttTimestamp = parseFloat(extractTimestamp(vttUrl) ?? "0");

  // Extract the X-TIMESTAMP-MAP line
  const xTimestampMapLine = vttContent
    .split("\n")
    .find((line) => line.includes("X-TIMESTAMP-MAP"));

  if (!xTimestampMapLine) {
    // throw new Error("No X-TIMESTAMP-MAP found in the VTT file");
    return undefined;
  }

  const { mpegts: vttMpegts, local: _ } = parseTimestampMap(
    xTimestampMapLine ?? ""
  );

  // console.log(vttMpegts, vttLocal);

  //console.log(vttMpegts, vttLocal);

  // Convert MPEGTS to seconds
  //const vttStartSeconds = mpegtsToSeconds(vttMpegts);

  //console.log("vttStartSeconds", vttStartSeconds);

  // console.log(vttStartSeconds);

  const cueRegex = /(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})/g;
  const cues: { inTime: number; outTime: number; duration: number }[] = [];

  // Match each cue and calculate its in/out times
  let match;

  const time = (vttMpegts - initPts) / 90000;

  while ((match = cueRegex.exec(vttContent)) !== null) {
    const localInTime = localTimeToSeconds(match[1]);
    const localOutTime = localTimeToSeconds(match[2]);

    //console.log(match);

    //console.log(localInTime, localOutTime);

    // In and Out time in Unix time
    const inTime = time + localInTime;
    const outTime = time + localOutTime;

    cues.push({
      inTime: inTime,
      outTime: outTime,
      duration: localOutTime - localInTime,
    });
  }

  return cues[0];
}

/**
 * Retrieves the subtitle text from an array of lines.
 * 
 * @param lines - An array of strings representing the lines of the subtitle.
 * @param num - An optional number indicating the line number to retrieve. If not provided, the last line will be retrieved.
 * @returns The subtitle text from the specified line number, or an empty string if the line is undefined, contains "X-TIMESTAMP-MAP" or "WEBVTT", or is empty.
 */
export function getSubtitleText(lines: string[], num?: number) {
  const _num = num !== undefined ? num - 1 : lines.length - 1;
  // let text = "";

  const text = lines[_num];

  if (text === undefined) return "";

  if (text && text.includes("X-TIMESTAMP-MAP")) {
    return "";
  }

  if (text && text.includes("WEBVTT")) {
    return "";
  }

  if (text.trim().length === 0) {
    return getSubtitleText(lines, _num);
  }

  return text;
}

/**
 * Fetches VTT subtitles from the specified URLs, calculates the in and out times,
 * and invokes the provided callback function with each subtitle.
 * 
 * @param urls - An array of URLs pointing to VTT subtitle files.
 * @param initPts - The initial PTS (Presentation Time Stamp) value.
 * @param callback - A callback function that will be called with each subtitle.
 * @returns An array of subtitle tracks.
 */
export async function fetchVtt(
  urls: string[],
  initPts: number,
  callback: (s: Subtitle) => void
) {
  try {
    const tracks = [];

    for (const vtt of urls) {
      const _url = BASE_HLS_URL + vtt;

      const res = await fetch(_url);
      const text = await res.text();

      const value = calculateInOutTimes(text, _url, initPts);

      const lines = text.split("\n");

      const _text = getSubtitleText(lines);

      if (_text.trim().length === 0) {
        continue;
      }

      if (value) {
        let subtitle: Subtitle = {
          text: _text,
          start: value.inTime,
          end: value.outTime,
        };

        callback(subtitle);

        tracks.push(subtitle);
      }

    }

    return tracks;
  } catch (error) {
    console.error(error);
  }
}
