export type SiteConfig = typeof siteConfig;
import { Info } from "lucide-react";

export const siteConfig = {
  name: "EMS BT",
  description: "EMS BT Application, EMS Broadcast technology LLC",
  navItems: [
    {
      label: Info,
      href: "/about",
    },
  ],
  navMenuItems: [

    {
      label: "About",
      href: "/about",
    },
    {
      label: "Help & Feedback",
      href: "/help-feedback",
    },
    {
      label: "Logout",
      href: "/logout",
    },
  ],
  links: {
    github: "https://github.com/nextui-org/nextui",
    twitter: "https://twitter.com/getnextui",
    docs: "https://nextui-docs-v2.vercel.app",
    discord: "https://discord.gg/9b6yyZKmH4",
    sponsor: "https://patreon.com/jrgarciadev",
  },
};
