import { UserProvider } from "@/proivder/userProvider";
import { Button } from "@nextui-org/button";
import { Divider } from "@nextui-org/divider";
import { Input } from "@nextui-org/input";
import { Link } from "@nextui-org/link";
import { Facebook, Twitter } from "lucide-react";
import { useRef, useState } from "react";
import { useToast } from "./ui/use-toast";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import Recaptcha from "reaptcha";

interface LoginProps {
  onClose: () => void;
}

export default function Login({ onClose }: LoginProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [email, setEmail] = useState("");

  const captchRef = useRef<Recaptcha | null>(null);

  const [isRegister, setIsRegister] = useState(false);

  const [isVerfiy, setIsVerify] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");

  const { toast } = useToast();

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");

  const [execution, setExecution] = useState("");

  const [_, setVerified] = useState(false);

  const captchaKey = useRef("");
  const captchaMethod = useRef("");

  function recaptchaResponse(e: any) {
    captchaKey.current = e;
    if (captchaMethod.current === "register") {
      register();
    }
    if (captchaMethod.current === "login") {
      login();
    }

    if (captchaMethod.current === "forget") {
      forgetPassowrd();
    }

    if (captchaMethod.current === "reset") {
      changePassword();
    }

    setVerified(true);
  }

  async function login() {
    if (username.length === 0 || password.length === 0) {
      toast({
        title: "",
        description: "Please enter username and password",
        duration: 5000,
      });
      return;
    }
    try {
      // set loading to true
      setLoading(true);
      // call login function from provider
      const result = await UserProvider.login(
        { username, password },
        captchaKey.current!
      );
      // set loading to false
      setLoading(false);
      // close modal

      if (result.password_is_temporary) {
        setIsPasswordReset(true);
        setPassword("");

        captchRef.current?.reset();
        return;
      }

      onClose();
    } catch (error: any) {
      // set loading to false
      setLoading(false);
      // show error message

      // reset captcha
      captchRef.current?.reset();

      if (error.message.includes("verified")) {
        setIsVerify(true);
        const response = await UserProvider.resendVerificationCode(username);

        setExecution(response.execution);
        return;
      }

      toast({
        title: "Invalid credentials",
        description: "Invalid username or password",
        duration: 5000,
      });
    }
  }

  async function register() {
    if (!email.includes("@")) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email",
        duration: 5000,
      });
      return;
    }
    try {
      // set loading to true
      setLoading(true);
      // call register function from provider
      const result = await UserProvider.register(
        {
          username,
          password,

          email: email,
          first_name: name,
          last_name: lastname,
        },
        captchaKey.current!
      );

      setExecution(result.execution);

      await UserProvider.sendVerificationEmail(
        result.execution,
        captchaKey.current!
      );

      captchRef.current?.reset();

      setIsVerify(true);
      // set loading to false
      setLoading(false);
    } catch (error: any) {
      // set loading to false
      setLoading(false);
      // show error message

      // reset captcha
      captchRef.current?.reset();

      //   toast({
      //     title: "Invalid credentials",
      //     description: "Invalid username or password",
      //     duration: 5000,
      //   });
    }
  }

  async function handleVerify() {
    // verify code
    try {
      setLoading(true);
      await UserProvider.verifyEmail(execution, code);
      await login();
      setLoading(false);
      captchRef.current?.reset();
      onClose();
    } catch (error: any) {
      // set loading to false
      setLoading(false);
      // show error message

      // reset captcha
      captchRef.current?.reset();

      //   toast({
      //     title: "Invalid credentials",
      //     description: "Invalid username or password",
      //     duration: 5000,
      //   });
    }
  }

  async function forgetPassowrd() {
    // check is email is valid

    if (!email.includes("@")) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email",
        duration: 5000,
      });
      return;
    }
    try {
      // set loading to true
      setLoading(true);
      // call login function from provider
      await UserProvider.sendTempPassword(email, captchaKey.current!);
      // set loading to false
      setLoading(false);
      // close modal
      setIsForgetPassword(false);
      // show success message
      captchRef.current?.reset();
      toast({
        title: "Email sent",
        description: "A temporary password has been sent to your email",
        duration: 5000,
      });
    } catch (error: any) {
      // set loading to false
      setLoading(false);
      // show error message

      // reset captcha
      captchRef.current?.reset();
    }
  }

  async function changePassword() {
    if (password.length === 0) {
      toast({
        title: "Invalid password",
        description: "Please enter a valid password",
        duration: 5000,
      });
      captchRef.current?.reset();
      return;
    }

    if (password !== repeatedPassword) {
      toast({
        title: "Passwords do not match",
        description: "Please enter the same password",
        duration: 5000,
      });
      captchRef.current?.reset();
      return;
    }
    try {
      // set loading to true
      setLoading(true);
      // call login function from provider
      await UserProvider.resetPassword(password, captchaKey.current!);
      // set loading to false
      setLoading(false);
      // close modal
      setIsPasswordReset(false);
      // show success message
      toast({
        title: "Password changed",
        description: "Your password has been changed successfully",
        duration: 5000,
      });

      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("id_token");

      captchRef.current?.reset();
    } catch (error: any) {
      // set loading to false
      setLoading(false);
      // show error message

      // reset captcha
      captchRef.current?.reset();
    }
  }

  return (
    <>
      <Recaptcha
        size="invisible"
        sitekey="6Ld0xjsqAAAAAGcS5MaGuxGa_QVRiL_4NcbwZEX5"
        onVerify={recaptchaResponse}
        ref={(e) => (captchRef.current = e)}
      />

      {isPasswordReset ? (
        <>
          <Card className="w-[350px] mx-auto mt-10">
            <CardHeader>
              <div className=" flex justify-center items-center flex-col w-full gap-2 mb-4">
                <h2 className=" font-semibold text-xl">Change your Password</h2>
                <p className="text-sm text-center">
                  Please enter your new password.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <div className=" flex flex-col gap-3">
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="text-center text-2xl  w-full"
                  placeholder="Password"
                  onKeyDown={(e) => {
                    {
                      if (e.key === "Enter") {
                        captchaMethod.current = "reset";
                        captchRef.current?.execute();
                      }
                    }
                  }}
                />

                <Input
                  type="password"
                  value={repeatedPassword}
                  onChange={(e) => setRepeatedPassword(e.target.value)}
                  className="text-center text-2xl  w-full"
                  placeholder="Repeat Password"
                  onKeyDown={(e) => {
                    {
                      if (e.key === "Enter") {
                        captchaMethod.current = "reset";
                        captchRef.current?.execute();
                      }
                    }
                  }}
                />
              </div>
            </CardBody>
            <CardFooter>
              <Button
                className="w-full"
                type="submit"
                onClick={() => {
                  captchaMethod.current = "reset";
                  captchRef.current?.execute();
                }}
                disabled={password.length === 0}
                isLoading={loading}
              >
                Change Password
              </Button>
            </CardFooter>
          </Card>
        </>
      ) : isForgetPassword ? (
        <>
          <Card className="w-[350px] mx-auto mt-10">
            <CardHeader>
              <div className=" flex justify-center items-center flex-col w-full gap-2 mb-4">
                <h2 className=" font-semibold text-xl">Forget Password</h2>
                <p className="text-sm text-center">
                  Please enter your email to reset your password.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-center text-2xl w-full dark:inputDarkModeOverride"

                placeholder="Email"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    captchaMethod.current = "forget";
                    captchRef.current?.execute();
                  }
                }}
              />
            </CardBody>
            <CardFooter>
              <Button
                className="w-full"
                type="submit"
                isLoading={loading}
                onClick={() => {
                  captchaMethod.current = "forget";
                  captchRef.current?.execute();
                }}
                disabled={email.length === 0}
              >
                Send
              </Button>
            </CardFooter>
          </Card>
        </>
      ) : isVerfiy ? (
        <>
          <Card className="w-[350px] mx-auto mt-10">
            <CardHeader>
              <div className=" flex justify-center items-center flex-col w-full gap-2 mb-4">
                <h2 className=" font-semibold text-xl">
                  Verification Required
                </h2>
                <p className="text-sm text-center">
                  Please enter the 6-digit code sent to your email.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <Input
                type="text"
                inputMode="numeric"
                pattern="\d{6}"
                maxLength={6}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="text-center text-2xl tracking-widest w-full dark:inputDarkModeOverride"
                placeholder="------"
              />
            </CardBody>
            <CardFooter>
              <Button
                className="w-full"
                type="submit"
                onClick={handleVerify}
                isLoading={loading}
                disabled={code.length !== 6}
              >
                Verify
              </Button>
            </CardFooter>
          </Card>
        </>
      ) : !isRegister ? (
        <div className="flex items-center justify-center ">
          <div className="max-w-md w-full p-1 placeholder:rounded-xl rounded-md shadow-md  bg-opacity-20">
            <div className="text-center">
              <h2 className="mt-4 text-3xl font-extrabold ">Login</h2>
              <p className="mt-2 text-sm text-white my-4">
                Or{" "}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setIsRegister(true);
                  }}
                  className=" text-primary cursor-pointer font-semibold underline  underline-offset-4"
                >
                  Create a new account
                </Link>
              </p>
            </div>

            <div className="rounded-md shadow-sm -space-y-px flex flex-col gap-4">
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <Input
                  id="username"
                  name="username"
                  type="email"
                  required
                  className="rounded-t-md"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}

                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="rounded-b-md"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    {
                      if (e.key === "Enter") {
                        captchaMethod.current = "login";
                        captchRef.current?.execute();
                      }
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setIsForgetPassword(true);
                  }}
                  className="font-medium text-primary cursor-pointer hover:text-primary-dark m-4"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <Button
                isLoading={loading}
                type="submit"
                className="w-full"
                onClick={() => {
                  captchaMethod.current = "login";
                  captchRef.current?.execute();
                }}
              >
                Sign in
              </Button>
            </div>

            <Divider className="my-8" />

            <div className="grid grid-cols-3 gap-3">
              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=google`}
              >
                <Button
                  startContent={
                    <svg
                      className="min-w-4"
                      viewBox="0 0 24 24"
                      fill="transparent"
                      stroke="currentColor"
                    >
                      <path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"></path>
                    </svg>
                  }
                  variant="bordered"
                  className="w-full"
                >
                  Google
                </Button>
              </a>

              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=twitter`}
              >
                <Button
                  startContent={<Twitter className=" min-w-4" />}
                  variant="bordered"
                  className="w-full"
                >
                  Twitter
                </Button>
              </a>
              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=facebook`}
              >
                <Button
                  startContent={<Facebook className=" min-w-4" />}
                  variant="bordered"
                  className="w-full"
                >
                  Facebook
                </Button>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center ">
          <div className="max-w-md w-full p-1 placeholder:rounded-xl rounded-md shadow-md  bg-opacity-20">
            <div className="text-center">
              <h2 className=" text-3xl font-extrabold ">Register</h2>
              <p className="mt-2 text-sm text-white">
                Or{" "}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setIsRegister(false);
                  }}
                  className=" font-semibold text-primary cursor-pointer underline  underline-offset-4 my-3"
                >
                  Login
                </Link>
              </p>
            </div>

            <div className="rounded-md shadow-sm -space-y-px flex flex-col gap-4">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="rounded-t-md"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <Input
                  id="username"
                  name="username"
                  type="email"
                  required
                  className="rounded-t-md"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="rounded-b-md"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="rounded-b-md"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="name" className="sr-only">
                  Last name
                </label>
                <Input
                  id="last_name"
                  name="last_name"
                  type="text"
                  required
                  className="rounded-b-md"
                  placeholder="Last name"
                  onChange={(e) => setLastname(e.target.value)}
                  onKeyDown={(e) => {
                    {
                      if (e.key === "Enter") {
                        captchaMethod.current = "register";
                        captchRef.current?.execute();
                      }
                    }
                  }}
                />
              </div>

              <div>
                <Button
                  isLoading={loading}
                  type="submit"
                  className="w-full"
                  onClick={() => {
                    captchaMethod.current = "register";
                    captchRef.current?.execute();
                  }}
                >
                  Sign up
                </Button>
              </div>
            </div>

            <Divider className="my-8" />

            <div className="mt-6 grid grid-cols-3 gap-3">
              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=google`}
              >
                <Button
                  startContent={
                    <svg
                      className="min-w-4"
                      viewBox="0 0 24 24"
                      fill="transparent"
                      stroke="currentColor"
                    >
                      <path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"></path>
                    </svg>
                  }
                  variant="bordered"
                  className="w-full"
                >
                  Google
                </Button>
              </a>
              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=twitter`}
              >
                <Button
                  startContent={<Twitter className=" min-w-4" />}
                  variant="bordered"
                  className="w-full"
                >
                  Twitter
                </Button>
              </a>
              <a
                href={`https://api.ovideo.app/auth/realms/emsbt/protocol/openid-connect/auth?client_id=emsbt_web&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_idp_hint=facebook`}
              >
                <Button
                  startContent={<Facebook className=" min-w-4" />}
                  variant="bordered"
                  className="w-full"
                >
                  Facebook
                </Button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
