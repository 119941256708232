import useAppStore from "@/store/store";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import {
  Download,
  Edit,
  Lock,
  LockOpen,
  Play,
  ShieldAlert,
  ShieldCheck,
  Trash2,
} from "lucide-react";
import React from "react";

interface ActionMenuProps {
  children: React.ReactNode;
  isActive: boolean;
  isPlaying?: boolean;
  isContextMenu?: boolean;
  isAllow: boolean;
  isPublic: boolean;
  isProtected: boolean;
  onPlay: () => void;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
  onActivate: () => void;
  onDeactivate: () => void;
  onExport: () => void;
  onPublic: (is_public: boolean) => void;
}

export default function ActionMenu({
  children,
  isActive,
  onDelete,
  onEdit,
  onPlay,
  onActivate,
  onDeactivate,
  isContextMenu,
  isPlaying,
  onExport,
  isAllow,
  onPublic,
  isPublic,
  isProtected,
}: ActionMenuProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const isLoggedin = useAppStore((state) => state.isLoggedin);

  const roles = useAppStore((state) => state.userRoles);

  return (
    <Dropdown
      shouldBlockScroll
      isOpen={isOpen}
      trigger="longPress"
      onOpenChange={(open) => {
        if (!open) {
          setIsOpen(open);
        }
      }}
    >
      <DropdownTrigger
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isContextMenu) {
            setIsOpen(true);
          }
          // setIsOpen(true);
        }}
        onContextMenu={(e) => {
          if (isContextMenu) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }
        }}
      >
        {children}
      </DropdownTrigger>
      <DropdownMenu
        disabledKeys={
          !isLoggedin
            ? [
                "copy",
                "export",
                "edit",
                "active",
                "deactivate",
                "delete",
                isActive ? "" : "play",
              ]
            : isActive
              ? [isPlaying ? "" : "export"]
              : ["play", "copy", "export"]
        }
        variant="faded"
        aria-label="Dropdown menu with description"
      >
        <DropdownItem
          key="play"
          description="Play stream"
          startContent={<Play />}
          onPress={onPlay}
        >
          Play
        </DropdownItem>
        {
          <DropdownItem
            key="edit"
            hidden={
              !isAllow ||
              !(roles.includes("paid_user") && roles.includes("admin"))
            }
            description="Edit the stream"
            startContent={<Edit />}
            className={isAllow && !isProtected ? "" : " hidden"}
            onPress={onEdit}
          >
            Edit
          </DropdownItem>
        }
        {/* <DropdownItem
          key="copy"
          description="Copy the stream URL"
          startContent={<Copy />}
          onPress={onCopy}
        >
          Copy
        </DropdownItem> */}

        <DropdownItem
          key="export"
          description="Export as public URL"
          startContent={<Download />}
          onPress={onExport}
          className={isAllow && !isProtected ? " " : " hidden"}
          
        >
          Export
        </DropdownItem>

        <DropdownItem
          key="public"
          className={
            !isAllow ||
            !(roles.includes("admin") || roles.includes("paid_user"))
              ? "hidden"
              : ""
          }
          description={
            isPublic ? "Make the source private" : "Make the source public"
          }
          startContent={isPublic ? <Lock /> : <LockOpen />}
          onPress={() => onPublic(!isPublic)}
          hidden={!isAllow}
        >
          {isPublic ? "Make private" : "Make public"}
        </DropdownItem>

        {!isActive ? (
          <DropdownItem
            key="active"
            hidden={!isAllow}
            description="Activate the stream"
            startContent={<ShieldCheck />}
            className={isAllow ? "" : " hidden"}
            onPress={onActivate}
          >
            Activate
          </DropdownItem>
        ) : (
          <DropdownItem
            key="deactivate"
            hidden={!isAllow}
            className={isAllow ? "" : " hidden"}
            description="Deactivate the stream"
            startContent={<ShieldAlert />}
            onPress={onDeactivate}
          >
            Deactivate
          </DropdownItem>
        )}

        {
          <DropdownItem
            key="delete"
            className={isAllow && !isProtected ? "text-danger " : " hidden"}
            color="danger"
            description="Delete the stream"
            hidden={!isAllow || isProtected}
            startContent={<Trash2 />}
            onPress={onDelete}
          >
            Delete file
          </DropdownItem>
        }
      </DropdownMenu>
    </Dropdown>
  );
}
