import { useState } from "react";
import { Modal, ModalHeader, ModalContent } from "@nextui-org/modal";
import { useDisclosure } from "@nextui-org/modal";
import { Input } from "@nextui-org/input";
import { VideoIcon } from "lucide-react";
import { NavItem } from "./bottomBar";

import StreamList from "./streamList";

import useAppStore from "@/store/store";

const ChannelModal = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { onOpen, onOpenChange, isOpen, onClose } = useDisclosure();
  const channels = useAppStore((state) => state.channels);
  const channel = useAppStore((state) => state.channel);
  const setChannel = useAppStore((state) => state.setChannel);

  const player = useAppStore((state) => state.videoPlayer);

  const filteredChannels = channels.filter((channel) =>
    channel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <NavItem
        icon={<VideoIcon className="w-5 h-5" />}
        label="Streams"
        onClick={() => onOpen()}
        isActive={false}
      />

      <Modal
        aria-labelledby="modal-title"
        backdrop="transparent"
        className=" min-h-[50vh] w-full rounded-sm"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalHeader>
          <h2 id="modal-title">Channels</h2>
        </ModalHeader>
        <ModalContent>
          <Input
            isClearable
            fullWidth
            color="default"
            size="sm"
            placeholder="Search by name"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ marginTop: "20px" }}>
            <StreamList
              channel={channel!}
              items={filteredChannels.filter((c) =>
                c.name.toLowerCase().includes(searchTerm.toLowerCase())
              )}
              videoPlayer={player!}
              onChannel={(c) => setChannel(c)}
              onDelete={() => null}
              onExport={() => null}
              onPlay={() => onClose()}
              onUpdate={() => null}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChannelModal;
